import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';

const NewClientDialog = ({ isOpen, onClose, newClient, setNewClient, handleCreateNewClient }) => {
	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Додавання нового клієнта</DialogTitle>
			<DialogContent>
				<div className="mt-1.5">
					<TextField
						label="Ім'я"
						value={newClient.name}
						onChange={(event) => setNewClient({...newClient, name: event.target.value})}
						className="w-full"
					/>
					<TextField
						type="number"
						label="Телефон"
						value={newClient.phone}
						onChange={(event) => setNewClient({...newClient, phone: event.target.value})}
						className="w-full !mt-5"
						sx={{
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
					/>
					<TextField
						type="number"
						label="Тариф"
						value={newClient.rate}
						onChange={(event) => setNewClient({...newClient, rate: event.target.value})}
						className="w-full !mt-5"
						sx={{
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
					/>
					<TextField
						type="number"
						label="Доля терапевта"
						value={newClient.share}
						onChange={(event) => setNewClient({...newClient, share: event.target.value})}
						className="w-full !mt-5"
						sx={{
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
					/>
				</div>
				<div className="grid grid-cols-2 gap-10 mt-5">
					<Button
						fullWidth
						variant="outlined"
						color="error"
						onClick={onClose}
					>
						Відмінити створення
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleCreateNewClient}
					>
						Додати клієнта
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
};

export default NewClientDialog;