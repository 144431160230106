import React, { useEffect, useState } from 'react';
import client from '../../services/core/limboclient';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from '../therapy-tests/index.module.scss';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import EditTherapistModal from './components/edit-therapist-modal';

const columns = [
	{ id: 'firstName', label: "Ім'я" },
	{ id: 'lastName', label: 'Прізвище' },
	{ id: 'rate', label: 'Тариф' },
	{ id: 'share', label: 'Доля терапевта' },
];

const AdminTherapistsPage = () => {
	const [therapistList, setTherapistList] = useState([]);
	const [selectedTherapist, setSelectedTherapist] = useState(null);
	const [isOpenModal, setIsOpenModal] = useState(false);

	useEffect(() => {
		if (!isOpenModal) {
			client.call(`=>therapist_list~`).then(result => {
				setTherapistList(result);
			});
		}
	}, [isOpenModal]);

	return (
		<div>
			<TableContainer
				sx={{ width: '100%', position: 'relative', marginBottom: '20px', marginTop: '10px' }}
				className="overflow-y-auto max-h-[calc(100vh-150px)]"
			>
				<Table stickyHeader aria-labelledby="tableTitle" size="small">
					<TableHead>
						<TableRow>
							{therapistList.length > 0 && columns.map((item) => (
								<TableCell
									key={item.id}
									align="left"
									style={{ fontWeight: 'bold' }}
								>
									{item.label}
								</TableCell>
							))}
							<TableCell
								key="action"
								align="right"
							/>
						</TableRow>
					</TableHead>
					<TableBody>
						{therapistList?.map((row, index) => {
							return (
								<TableRow
									hover
									tabIndex={-1}
									key={index.toString()}
									onClick={() => {
										setIsOpenModal(true);
										setSelectedTherapist(row);
									}}
								>
									{columns?.map((column) => {
										return (
											<TableCell key={column.id} align="left" className={styles.rowItemContainer}>
												{row[column.id]}
											</TableCell>
										);
									})}
									<TableCell key="edit" align="right" className={styles.rowItemContainer}>
										<BorderColorTwoToneIcon
											onClick={() => {
												setIsOpenModal(true);
												setSelectedTherapist(row);
											}}
											className="cursor-pointer"
											color="primary"
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<EditTherapistModal
				isOpen={isOpenModal}
				onClose={() => {
					setIsOpenModal(false);
					setSelectedTherapist(null);
				}}
				selectedTherapist={selectedTherapist}
			/>
		</div>
	);
};

export default AdminTherapistsPage;