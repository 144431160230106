import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import moment from 'moment';

const LogModal = ({ isOpen, handleClose, selectedLog, handleLevelView }) => {
	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xl">
			<DialogContent>
				<div className="flex flex-row">
					<p className="font-bold basis-1/4">Date/time:</p>
					<p>{moment(selectedLog?.date).format('llll')}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">Content:</p>
					<p>{selectedLog?.content}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">IP:</p>
					<p>{selectedLog?.ip}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">URI:</p>
					<p>{selectedLog?.uri}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">Request body:</p>
					<p>{selectedLog?.requestBody}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">Session:</p>
					<p>{selectedLog?.session}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">Request:</p>
					<p>{selectedLog?.request}</p>
				</div>
				<div className="flex flex-row mt-5">
					<p className="font-bold basis-1/4">Level:</p>
					<p>{handleLevelView(selectedLog?.level)}</p>
				</div>
			</DialogContent>
		</Dialog>
	)
};

export default LogModal;
