import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import limboClient from '../../services/core/limboclient';
import {
	Button,
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	FormControl,
	Select,
	OutlinedInput,
	MenuItem
} from '@mui/material';
import styles from './index.module.scss';
import HorizontalRuleTwoToneIcon from '@mui/icons-material/HorizontalRuleTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import FilterAltOffTwoToneIcon from '@mui/icons-material/FilterAltOffTwoTone';
import LogModal from './components/log-modal';

const columns = [
	{ id: 'date', label: 'Дата та час' },
	{ id: 'content', label: 'Контент' },
	{ id: 'ip', label: 'IP' },
	{ id: 'uri', label: 'URI' },
	{ id: 'requestBody', label: 'Request body' },
	{ id: 'level', label: 'Level' }
];

const LogsPage = () => {
	const [logsList, setLogsList] = useState([]);
	const [filters, setFilters] = useState({
		id: '',
		level: '',
		phrase: '',
		session: '',
		request: '',
		uri: '',
		ip: ''
	});
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(false);
	const [distanceBottom, setDistanceBottom] = useState(0);
	const tableContainer = useRef(null);
	const [isOpenSelectedDialog, setIsOpenSelectedDialog] = useState(false);
	const [selectedLog, setSelectedLog] = useState(null);

	const loadMore = useCallback(() => {
		const loadLogs = () => {
			if (hasMore) {
				const { id, ip, level, phrase, request, session, uri } = filters;

				limboClient.call(`=> logs_get ~ ${JSON.stringify({
					page: page + 1,
					...(!!id && { id: filters.id }),
					...(ip && { ip: filters.ip }),
					...(!!level && { level: filters.level }),
					...(!!phrase && { phrase: filters.phrase }),
					...(!!request && { request: filters.request }),
					...(!!session && { session: filters.session }),
					...(!!uri && { uri }),
				})}`).then((result) => {
					setLogsList([...logsList, ...result]);
					setPage(page + 1);
					if (result?.length < 20) {
						setHasMore(false);
					}
					setLoading(false);
				});
			}
		}
		setLoading(true);
		loadLogs();
	}, [logsList, filters]);

	const scrollListener = useCallback(() => {
		let bottom = tableContainer.current.scrollHeight - tableContainer.current.clientHeight;
		if (!distanceBottom) {
			setDistanceBottom(Math.round(bottom * 0.2));
		}
		if (tableContainer.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
			loadMore();
		}
	}, [hasMore, loadMore, loading, distanceBottom]);

	useLayoutEffect(() => {
		const tableRef = tableContainer.current;
		tableRef.addEventListener('scroll', scrollListener);
		return () => {
			tableRef.removeEventListener('scroll', scrollListener);
		}
	}, [scrollListener]);

	useEffect(() => {
		fetchLogs({ page });
	}, []);

	const fetchLogs = (config) => {
		limboClient.call(`=> logs_get ~ ${JSON.stringify(config)}`).then((result) => {
			setLogsList(result);
		});
	};

	const handleLevelView = (level) => {
		switch (level) {
			case 1: return <Chip label="Error(1)" color="error" size="small" className="!cursor-pointer w-full" />
			case 2: return <Chip label="Warn(2)" color="primary" size="small" className="!cursor-pointer w-full" />
			case 3: return <Chip label="Debug(3)" color="success" size="small" className="!cursor-pointer w-full" />
			default: return level
		}
	};

	const handleTableItemView = (value, columnType) => {
		if (!value) return <HorizontalRuleTwoToneIcon color="inherit" />
		switch (columnType) {
			case 'date': return <p className="w-[100px]">{moment(value).format('llll')}</p>
			case 'content': return <div className="w-[350px] overflow-hidden">
				<p className="w-full whitespace-normal">{value.substr(0, 500)}</p>
			</div>
			case 'requestBody': return <div className="w-[100px] overflow-hidden">
				<p className="w-full whitespace-norma">{value}</p>
			</div>
			case 'uri': return <p className="max-w-[50px]">{value}</p>
			case 'level': return handleLevelView(value)
			default: return value
		}
	};

	const searchLogs = () => {
		const { id, ip, level, phrase, request, session, uri } = filters;
		fetchLogs({
			page,
			...(!!id && { id: filters.id }),
			...(ip && { ip: filters.ip }),
			...(!!level && { level: filters.level }),
			...(!!phrase && { phrase: filters.phrase }),
			...(!!request && { request: filters.request }),
			...(!!session && { session: filters.session }),
			...(!!uri && { uri }),
		});
	};

	const clearFilters = async () => {
		setFilters({
			id: '',
			level: '',
			phrase: '',
			session: '',
			request: '',
			uri: '',
			ip: ''
		});
		await fetchLogs({});
		if (tableContainer.current) tableContainer.current.scrollTop = 0;
	};

	return (
		<div className="h-screen overflow-auto">
			<div className="border-b-2 border-black pb-3 mt-2">
				<div className="grid grid-cols-4 gap-3">
					<TextField
						key="id"
						label="Search by id"
						value={filters.id}
						onChange={(event) =>
							setFilters({...filters, id: event.target.value})
						}
						size="small"
					/>
					<TextField
						key="phrase"
						label="Search by phrase"
						onChange={(event) =>
							setFilters({...filters, phrase: event.target.value})
						}
						size="small"
					/>
					<TextField
						key="session"
						label="Search by session"
						value={filters.session}
						onChange={(event) =>
							setFilters({...filters, session: event.target.value})
						}
						size="small"
					/>
					<TextField
						key="request"
						label="Search by request"
						value={filters.request}
						onChange={(event) =>
							setFilters({...filters, request: event.target.value})
						}
						size="small"
					/>
					<TextField
						key="uri"
						label="Search by uri"
						value={filters.uri}
						onChange={(event) =>
							setFilters({...filters, uri: event.target.value})
						}
						size="small"
					/>
					<TextField
						key="ip"
						label="Search by ip"
						value={filters.ip}
						onChange={(event) =>
							setFilters({...filters, ip: event.target.value})
						}
						size="small"
					/>
					<FormControl variant="standard" key="select-level">
						<Select
							size="small"
							value={filters.level}
							input={<OutlinedInput />}
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							renderValue={(selected) => {
								if (selected?.length === 0)
									return <p className="opacity-70">Select level</p>;

								return <>{handleLevelView(selected)}</>
							}}
							onChange={(event) =>
								setFilters({...filters, level: event.target.value})
							}
						>
							{[1, 2, 3].map((level, index) => (
								<MenuItem
									value={level}
									key={index.toString()}
								>
									{handleLevelView(level)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button variant="contained" size="small" onClick={searchLogs}>
						Search
						<SearchIcon className="!ml-2"/>
					</Button>
				</div>
			</div>
			<TableContainer
				sx={{ maxHeight: "57vh", width: '100%', position: 'relative', marginBottom: 2, marginTop: 5 }}
				ref={tableContainer}
			>
				<Table stickyHeader aria-labelledby="tableTitle" size={"small"} className={styles.table}>
					<TableHead>
						<TableRow>
							{logsList.length > 0 && columns.map((item, index) => (
								<TableCell
									key={index.toString()}
									align="left"
									style={{ minWidth: 50, width: 'max-content', whiteSpace: 'nowrap' }}
								>
									{item.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{logsList.length > 0 && logsList.map((row, index) => {
							return (
								<TableRow
									hover
									tabIndex={-1}
									key={index.toString()}
									className={styles.tableRow}
									onClick={() => {
										setSelectedLog(row);
										setIsOpenSelectedDialog(true)
									}}
								>
									{columns.map((column) => {
										return (
											<TableCell key={column.id} align="left" className={styles.rowItemContainer}>
												{handleTableItemView(row[column.id], column.id)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{!logsList?.length && (
				<>
					<p className="text-3xl font-bold italic opacity-70">Відстуні логи для відображення...</p>
					<Button variant="contained" color="warning" className="!mt-5" onClick={clearFilters}>
						Скинути фільтрацію
						<FilterAltOffTwoToneIcon className="ml-2" />
					</Button>
				</>
			)}
			<LogModal
				isOpen={isOpenSelectedDialog}
				handleClose={() => {
					setIsOpenSelectedDialog(false);
					setSelectedLog(null);
				}}
				selectedLog={selectedLog}
				handleLevelView={handleLevelView}
			/>
		</div>
	);
};

export default LogsPage;