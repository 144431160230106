import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import styles from '../../../therapy-tests/index.module.scss';
import moment from 'moment/moment';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

const columns = [
	{ id: 'testName', label: 'Назва тесту' },
	{ id: 'prescriptionDate', label: 'Дата призначення тесту' },
];

const PrescriptionsTable = ({ tests, handleDeletePrescription }) => {
	const handleCellView = (value, columnType, assignment) => {
		switch (columnType) {
			case 'period': return <p>{assignment.period} {assignment.periodUnit === 1 ? 'дні' : 'тижні'}</p>
			case 'isDone': return value ? 'Так' : 'Ні';
			case 'firstDate': return moment(assignment.first_date || assignment.firstDate).locale('uk').format('LL');
			default: return value;
		}
	};

	return (
		<TableContainer sx={{ width: '100%', position: 'relative', marginBottom: '20px', marginTop: '10px' }}>
			<Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
				<TableHead>
					<TableRow>
						{tests.length > 0 && columns.map((item) => (
							<TableCell
								key={item.id}
								align="left"
								style={{ fontWeight: 'bold'}}
							>
								{item.label}
							</TableCell>
						))}
						<TableCell
							key="action"
							align="right"
						/>
					</TableRow>
				</TableHead>
				<TableBody>
					{tests?.map((row, index) => {
						return (
							<TableRow
								hover
								tabIndex={-1}
								key={index.toString()}
							>
								{columns?.map((column) => {
									return (
										<TableCell key={column.id} align="left" className={styles.rowItemContainer}>
											{handleCellView(row[column.id], column.id, row)}
										</TableCell>
									);
								})}
								<TableCell key="edit" align="right" className={styles.rowItemContainer}>
									<Tooltip title={`Видалити призначений тест "${row.testName}"`}>
										<DeleteForeverTwoToneIcon
											color="error"
											className="ml-2 cursor-pointer"
											onClick={() => handleDeletePrescription(row.id)}
										/>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
};

export default PrescriptionsTable;