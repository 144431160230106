import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Tooltip } from '@mui/material';
import LibraryAddTwoToneIcon from '@mui/icons-material/LibraryAddTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import testService from '../../../../services/testService';
import { useNavigate } from 'react-router-dom';

const CreateTestModal = ({ isOpen, close }) => {
	const navigate = useNavigate();
	const [newTest, setNewTest] = useState({
		isPublic: false,
		daysToReload: 0,
		questions: [{text: '', options: [{text: '', points: 0, id: 'new'}]}]
	});
	const canDeleteQuestion = newTest.questions.filter((item) => item !== null).length > 1;

	useEffect(() => {
		if (!isOpen) {
			setNewTest({questions: [{text: '', options: [{text: '', points: 0, id: 'new'}]}]});
		}
	}, [isOpen]);

	const isValidTest = () => {
		return newTest?.name?.length > 0;
	}

	const handleCreateTest = () => {
		const validTestQuestions = newTest.questions.filter((item) => item !== null);
		const testWithValidOptions = validTestQuestions.map((item) => {
			const validOptions = item.options.filter((item) => item !== null);

			return {...item, options: validOptions}
		});

		if (isValidTest()) testService.createTest({...newTest, questions: testWithValidOptions}, () => navigate('/r/sign-in'));

		close();
	};

	const handleAddQuestion = () => {
		const newEmptyQuestion = {text: '', options: [{text: '', points: 0, id: 'new'}]};

		setNewTest({...newTest, questions: [...newTest.questions, newEmptyQuestion]});
	};

	const handleDeleteQuestion = (questionIndex) => {
		if (!canDeleteQuestion) return;

		const updatedQuestionList = newTest.questions.map((item, index) => {
			if (index === questionIndex) return null;
			return item;
		});

		setNewTest({...newTest, questions: [...updatedQuestionList]});
	};

	const changeQuestionName = (questionIndex, newName) => {
		const questions = newTest.questions;
		const selectedQuestion = newTest.questions[questionIndex];
		questions[questionIndex] = {...selectedQuestion, text: newName};

		setNewTest({...newTest, questions});
	};

	const addOptionToQuestion = (questionIndex) => {
		const questions = newTest.questions;
		const selectedQuestion = newTest.questions[questionIndex];
		questions[questionIndex] = {...selectedQuestion, options: [...selectedQuestion.options, {text: '', points: 0, id: 'new'}]};
		setNewTest({...newTest, questions});
	};

	const changeOptionInQuestion = (questionIndex, optionIndex, optionText, optionPoint) => {
		const updatedQuestionsData = newTest.questions.map((item, index) => {
			if (index === questionIndex) {
				const updatedOptions = item.options.map((option, answerIndex) => {
					if (answerIndex === optionIndex) return {text: optionText, points: optionPoint, id: 'new'};
					return option;
				});

				return {...item, options: updatedOptions}
			}

			return item;
		});

		setNewTest({...newTest, questions: updatedQuestionsData});
	}

	const deleteOptionInQuestion = (questionIndex, optionIndex) => {
		const updatedQuestionsData = newTest.questions.map((item, index) => {
			if (index === questionIndex) {
				const updatedOptions = item.options.map((option, answerIndex) => {
					if (answerIndex === optionIndex) return null;
					return option;
				});

				return {...item, options: updatedOptions}
			}

			return item;
		});

		setNewTest({...newTest, questions: updatedQuestionsData});
	};

	return (
		<Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
			<DialogTitle>
				Створення нового тесту
			</DialogTitle>
			<DialogContent>
				<TextField
					value={newTest?.name}
					label="Назва тесту"
					variant="outlined"
					onChange={(event) =>
						setNewTest({...newTest, name: event.target.value})
					}
					className="w-[100%] !mt-2"
					required
				/>
				<TextField
					value={newTest?.details}
					label="Опис тесту"
					variant="outlined"
					onChange={(event) =>
						setNewTest({...newTest, details: event.target.value})
					}
					className="w-[100%] !mt-5"
				/>
				<TextField
					value={newTest?.daysToReload?.toString()}
					label="Кількість днів, скільки тест буде не доступний клієнту після проходження"
					variant="outlined"
					type="number"
					onChange={(event) =>
						setNewTest({...newTest, daysToReload: Number(event.target.value)})
					}
					sx={{
						"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
							display: "none",
						},
						"& input[type=number]": {
							MozAppearance: "textfield",
						},
					}}
					className="w-[100%] !mt-5"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={newTest?.isPublic}
							onChange={(event) => setNewTest({
								...newTest,
								isPublic: event.target.checked
							})}
						/>
					}
					label="Тест буде доступний для клієнтів без призначення"
					labelPlacement="start"
					className="!mt-5 !ml-0"
				/>
				<div className="mb-5 mt-5 flex items-center justify-between">
					{newTest?.questions?.length > 0 && <p className="font-bold italic">Питання та відповіді: </p>}
					<Button
						fullWidth
						variant="outlined"
						color="success"
						className="!w-max"
						onClick={() => handleAddQuestion()}
					>
						<LibraryAddTwoToneIcon color="success" className="mr-3" />
						Додати питання
					</Button>
				</div>
				<div>
					{newTest?.questions?.length > 0 && (
						newTest?.questions?.map((item, index) => {
							const canDeleteOptions = item?.options?.filter((item) => item !== null).length > 1;
							if(!item) return;

							return (
								<div className="mt-5 mb-5 border border-[#a1a1a1] rounded-lg p-5" id={index.toString()}>
									<div className="flex items-center">
										<TextField
											key={`${index}-question`}
											value={item?.val}
											onChange={(event) =>
												changeQuestionName(index, event.target.value)
											}
											variant="outlined"
											className="w-[100%] !mt-2"
											placeholder="Ваше питання..."
											multiline
											maxRows={3}
										/>
										<Tooltip title="Додати варіант" placement="top">
											<LibraryAddTwoToneIcon
												color="primary"
												className="ml-3 cursor-pointer"
												onClick={() => addOptionToQuestion(newTest.questions.indexOf(item))}
											/>
										</Tooltip>
										<Tooltip title="Видалити питання" placement="top">
											<HighlightOffTwoToneIcon
												color={canDeleteQuestion ? "error" : "disabled"}
												className="ml-3 cursor-pointer"
												onClick={() => handleDeleteQuestion(newTest.questions.indexOf(item))}
											/>
										</Tooltip>
									</div>
									<div className="grid grid-cols-1 gap-5 mt-5">
										{item?.options?.length > 0 && item?.options?.map((answer, answerIndex) => {
											if(!answer) return;

											return (
												<div className="flex items-center">
													<TextField
														id={answerIndex}
														hiddenLabel
														key={`${index}-value`}
														variant="filled"
														className="w-[100%]"
														placeholder="Відповідь..."
														onChange={(event) =>
															changeOptionInQuestion(index, answerIndex, event.target.value, answer.points)
														}
													/>
													<TextField
														hiddenLabel
														key={`${index}-points`}
														variant="filled"
														className="w-[100px] !ml-5"
														onChange={(event) =>
															changeOptionInQuestion(index, answerIndex, answer.text, event.target.value)
														}
													/>
													<Tooltip title="Видалити варіант відповіді" placement="top">
														<HighlightOffTwoToneIcon
															color={canDeleteOptions ? "error" : "disabled"}
															className="ml-3 cursor-pointer"
															onClick={() => {
																if (canDeleteOptions) deleteOptionInQuestion(index, answerIndex)
															}}
														/>
													</Tooltip>
												</div>
											)}
										)}
									</div>
								</div>
							)
						})
					)}
				</div>
				<div className="grid grid-cols-2 gap-10">
					<Button
						fullWidth
						variant="outlined"
						color="warning"
						onClick={close}
					>
						Відмінити створення
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => handleCreateTest()}
					>
						Створити тест
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
};

export default CreateTestModal;