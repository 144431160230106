import React, {useEffect, useState} from 'react';
import client from '../../services/core/limboclient';
import { Alert, Button, Dialog, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
	const navigate = useNavigate();
	const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
	const [therapist, setTherapist] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState({
		isOpen: false,
		alertType: 'success',
		message: ''
	});
	const [merchantId, setMerchantId] = useState(null);
	const [secret, setSecret] = useState(null);
	const [publicKey, setPublicKey] = useState(null);

	useEffect(() => {
		client.call('=> therapist_details ~ ').then(result => {
			if (result.success === false && result.error === 'not_authorized') {
				navigate('/r/sign-in');
			} else {
				setTherapist(result);
			}
		});
	}, []);

	useEffect(() => {
		client.call('=> payment_getMyMerchant ~').then(result => {
			if (result?.merchant) setMerchantId(result.merchant)
			if (result?.publicKey) setPublicKey(result.publicKey)
			if (result?.secret) setSecret(result.secret)
		});
	}, []);

	const handleDeleteAccount = () => {
		client.call('=>user_deleteAccount~').then(result => {
			if (result.success) navigate('/r/sign-in');
		});
	};

	const handleUpdateProfile = () => {
		client.call(`=>therapist_updateMyProfile~${JSON.stringify({
			details: therapist?.details ?? '',
			specialization: therapist?.specialization ?? '',
			img: therapist?.image ?? ''
		})}`).then(result => {
			if (result.success === false && result.error === 'not_authorized') {
				navigate('/r/sign-in');
			}
			if (result.success) {
				setOpenSnackbar({
					isOpen: true,
					alertType: 'success',
					message: 'Дані терапевта були успішно оновлені!'
				})
			}
		});
	};

	const handleUpdateMerchant = () => {
		client.call(`=> payment_updateMyMerchant ~ ${JSON.stringify({
			merchant: merchantId,
			publicKey: publicKey,
			secret: secret
		})}`).then(result => {
			if (result.success === false && result.error === 'not_authorized') {
				navigate('/r/sign-in');
			}
			if (result.success) {
				setOpenSnackbar({
					isOpen: true,
					alertType: 'success',
					message: 'Дані терапевта були успішно оновлені!'
				})
			}
		});
	}

	const handleUpdateName = () => {
		client.call(`=>user_updateMyProfile~${JSON.stringify({
			firstName: therapist?.firstName ?? '',
			lastName: therapist?.lastName ?? ''
		})}`).then(result => {
			if (result.success === false && result.error === 'not_authorized') {
				navigate('/r/sign-in');
			}
			if (result.success) {
				setOpenSnackbar({
					isOpen: true,
					alertType: 'success',
					message: 'Дані терапевта були успішно оновлені!'
				})
			}
		});
	};

	return (
		<div>
			{therapist?.image && (
				<img
					src={therapist?.image}
					alt={therapist?.image}
					className="mr-5 w-[70px] rounded-md mb-5"
				/>
			)}
			<div className="grid gap-5 grid-cols-4 w-max">
				<p className="flex items-center">Ім'я:</p>
				<TextField
					variant="outlined"
					placeholder="Ім'я..."
					size="small"
					value={therapist?.firstName}
					onChange={(event) => setTherapist({...therapist, firstName: event.target.value})}
					onBlur={handleUpdateName}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Прізвище:</p>
				<TextField
					variant="outlined"
					placeholder="Прізвище..."
					size="small"
					value={therapist?.lastName}
					onChange={(event) => setTherapist({...therapist, lastName: event.target.value})}
					onBlur={handleUpdateName}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Зображення:</p>
				<TextField
					variant="outlined"
					placeholder="Посилання на зображення..."
					size="small"
					value={therapist?.image}
					onChange={(event) => setTherapist({...therapist, image: event.target.value})}
					onBlur={handleUpdateProfile}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Спеціалізація:</p>
				<TextField
					variant="outlined"
					placeholder="Ваша спеціалізація..."
					size="small"
					value={therapist?.specialization}
					onChange={(event) => setTherapist({...therapist, specialization: event.target.value})}
					onBlur={handleUpdateProfile}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Опис:</p>
				<TextField
					variant="outlined"
					placeholder="Опис..."
					size="small"
					value={therapist?.details}
					onChange={(event) => setTherapist({...therapist, details: event.target.value})}
					onBlur={handleUpdateProfile}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Мерчант ID:</p>
				<TextField
					variant="outlined"
					placeholder="Ваш мерчант ID..."
					size="small"
					value={merchantId}
					onChange={(event) => setMerchantId(event.target.value)}
					onBlur={handleUpdateMerchant}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Публічний Ключ:</p>
				<TextField
					variant="outlined"
					placeholder="Ваш публічний ключ..."
					size="small"
					value={publicKey}
					onChange={(event) => setPublicKey(event.target.value)}
					onBlur={handleUpdateMerchant}
					className="w-[500px] col-span-3"
				/>
				<p className="flex items-center">Secret:</p>
				<TextField
					variant="outlined"
					placeholder="Secret..."
					size="small"
					value={secret}
					onChange={(event) => setSecret(event.target.value)}
					onBlur={handleUpdateMerchant}
					className="w-[500px] col-span-3"
				/>
				
			<Button
				variant="contained"
				color="error"
				onClick={() => setIsOpenRemoveDialog(true)}
				className="!mt-5"
			>
				Видалити акаунт
			</Button>
			<Dialog open={isOpenRemoveDialog} onClose={() => setIsOpenRemoveDialog(false)} fullWidth maxWidth="md">
				<DialogTitle>
					<p className="font-bold text-center">Ви впевнені, що хочете далити акаунт?</p>
				</DialogTitle>
				<DialogContent>
					<div className="grid grid-cols-2 gap-10">
						<Button
							fullWidth
							variant="outlined"
							color="error"
							onClick={() => setIsOpenRemoveDialog(false)}
						>
							Відмінити
						</Button>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleDeleteAccount}
						>
							Видалити акаунт
						</Button>
					</div>
				</DialogContent>
			</Dialog>
			<Snackbar
				open={openSnackbar.isOpen}
				autoHideDuration={5000}
				onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
			>
				<Alert
					onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
					severity={openSnackbar.alertType}
					sx={{ width: '100%' }}
				>
					{openSnackbar.message}
				</Alert>
			</Snackbar>
		</div>
	</div>
	)
};

export default ProfilePage;