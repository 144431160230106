import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

const DeleteTestModal = ({ isOpen, close, test, deleteTest }) => {
	return (
		<Dialog open={isOpen} onClose={close} fullWidth maxWidth="sm">
			<DialogTitle>{`Ви дійсно хочете видалити тест - ${test?.name}?`}</DialogTitle>
			<DialogContent>
				<p>Після видалення, ви не зможете відмінити зміни, тест буде видалено назавжди.</p>
				<div className="grid grid-cols-2 gap-10 mt-5">
					<Button
						fullWidth
						variant="outlined"
						color="error"
						onClick={close}
					>
						Відмінити видалення
					</Button>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={deleteTest}
					>
						Видалити тест
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
};

export default DeleteTestModal;