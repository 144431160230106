import React from 'react';
import {
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import moment from 'moment';
import styles from '../../../therapy-tests/index.module.scss';

const columns = [
	{ id: 'name', label: 'Настрій' },
	{ id: 'category', label: 'Категорія настрою' },
	{ id: 'note', label: 'Опис настрою' },
	{ id: 'appropriateness', label: 'Доречність' },
	{ id: 'strength', label: 'Вираженість' },
	{ id: 'intensity', label: 'Ступінь' },
	{ id: 'time', label: 'Дата та час' },
];

const MoodsTable = ({ moodsList, moodsCategories }) => {
	const handleCategoryView = (category) => {
		switch (category) {
			case 'Виснажений':
			case 'Неприємний': return <Chip label={category} color="error" />
			case 'Приємний':
			case 'Eнергійний': return <Chip label={category} color="success" />
			default: return <Chip label={category} />;
		}
	};

	const handleCellView = (value, columnType) => {
		switch (columnType) {
			case 'category': return <div className="grid grid-cols-2 gap-y-2 gap-x-2 w-max">
				{moodsCategories.find((item) => item.id === value)?.name?.split(' ').map((item) => handleCategoryView(item))}
			</div>
			case 'intensity':
			case 'strength':
			case 'appropriateness': return (
				<div className="flex items-center tracking-widest py-1 px-3 bg-light-gray w-max rounded-2xl text-[#595959]">
					<p className="text-sm font-bold">{value ?? 0}</p>/<p className="text-xs tracking-normal">10</p>
				</div>
			)
			case 'time': return moment(new Date(value)).locale('uk')?.format('LLL')
			default: return value;
		}
	};

	return (
		<TableContainer sx={{ width: '100%', position: 'relative', marginBottom: '20px', marginTop: '10px' }}>
			<Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
				<TableHead>
					<TableRow>
						{moodsList.length > 0 && columns.map((item) => (
							<TableCell
								key={item.id}
								align="left"
								style={{ fontWeight: 'bold'}}
							>
								{item.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{moodsList?.map((row, index) => {
						return (
							<TableRow
								hover
								tabIndex={-1}
								key={index.toString()}
							>
								{columns?.map((column) => {
									return (
										<TableCell key={column.id} align="left" className={styles.rowItemContainer}>
											{handleCellView(row[column.id], column.id)}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
};

export default MoodsTable;