import React, { useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import {
	AppBar,
	Button,
	DialogTitle,
	Snackbar,
	Toolbar,
	Typography,
	Dialog,
	DialogContent, TextField
} from '@mui/material';
import client from '../../services/core/limboclient';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import SignIn from '../auth/sign-in';

const SystemProfilePage = () => {
	const navigate = useNavigate();
	const [openSnackbar, setOpenSnackbar] = useState({
		isOpen: false,
		alertType: 'success',
		message: ''
	});
	const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
	const [isAuth, setIsAuth] = useState(true);
	const [user, setUser] = useState(null);

	useEffect(() => {
		(async () => {
			const isAuthorized = await authService.isAuthorizedUser();
			setIsAuth(isAuthorized);

			if (isAuthorized) {
				client.call('=>user_getMyProfile~').then(result => {
					if (result.success === false && result.error === 'not_authorized') {
						navigate('/r/sign-in');
					} else {
						setUser(result);
					}
				});
			}
		})();
	}, []);

	const handleDeleteAccount = () => {
		client.call('=>user_deleteAccount~').then(result => {
			if (result.success) navigate('/r/sign-in');
		});
	};

	const handleUpdateProfile = () => {
		client.call(`=>user_updateMyProfile~${JSON.stringify({
			firstName: user?.first_name ?? '',
			lastName: user?.last_name ?? ''
		})}`).then(result => {
			if (result.success === false && result.error === 'not_authorized') {
				setIsAuth(false);
			}
		});
	}

	return (
		<div className="px-10 py-20">
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar className="bg-primary flex items-center justify-between">
					<Typography variant="h5" noWrap component="div" className="flex items-center">
						<img src="/icons/logo.svg" alt="logo" />
						<span className="block ml-2">admin panel</span>
					</Typography>
				</Toolbar>
			</AppBar>
			{isAuth && !!user && (
				<>
					<div>
						<TextField
							variant="outlined"
							placeholder="Iм'я..."
							value={user?.first_name}
							onChange={(event) => setUser({...user, first_name: event.target.value})}
							onBlur={handleUpdateProfile}
							className="w-[500px]"
						/>
						<TextField
							variant="outlined"
							placeholder="Прізвище..."
							value={user?.last_name}
							onChange={(event) => setUser({...user, last_name: event.target.value})}
							onBlur={handleUpdateProfile}
							className="w-[500px] !mt-3"
						/>
					</div>
					<Button
						variant="contained"
						color="error"
						onClick={() => setIsOpenRemoveDialog(true)}
						className="!mt-5"
					>
						Видалити акаунт
					</Button>
				</>
			)}
			{!isAuth && (
				<SignIn
					navigateAfterLogin={() => {
						navigate('/r/user/profile')
						setIsAuth(true)
					}}
					isCanSignUp={false}
				/>
			)}
			<Dialog open={isOpenRemoveDialog} onClose={() => setIsOpenRemoveDialog(false)} fullWidth maxWidth="md">
				<DialogTitle>
					<p className="font-bold text-center">Ви впевнені, що хочете далити акаунт?</p>
				</DialogTitle>
				<DialogContent>
					<div className="grid grid-cols-2 gap-10">
						<Button
							fullWidth
							variant="outlined"
							color="error"
							onClick={() => setIsOpenRemoveDialog(false)}
						>
							Відмінити
						</Button>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleDeleteAccount}
						>
							Видалити акаунт
						</Button>
					</div>
				</DialogContent>
			</Dialog>
			<Snackbar
				open={openSnackbar.isOpen}
				autoHideDuration={5000}
				onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
			>
				<MuiAlert
					onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
					severity={openSnackbar.alertType}
					sx={{ width: '100%' }}
				>
					{openSnackbar.message}
				</MuiAlert>
			</Snackbar>
		</div>
	)
};

export default SystemProfilePage;