import React, { useState } from 'react';
import styles from '../sign-in/index.module.scss';
import { Box, Button, Link, Snackbar, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import limboClient from '../../../services/core/limboclient';
import MuiAlert from '@mui/material/Alert';

const SignUp = () => {
	const navigate = useNavigate();
	const [openSnackbar, setOpenSnackbar] = useState({
		isOpen: false,
		alertType: 'success',
		message: ''
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = data.get('email');
		const password = data.get('password');
		const firstName = data.get('name');
		const lastName = data.get('lastName');

		limboClient.call(`=>user_register ~ ${JSON.stringify({
				email, password, firstName, lastName
			})};`).then(result => {
			if (result.success === true) {
				navigate('/r/confirm');
			} else if (result.msg === 'User already exists') {
				setOpenSnackbar({
					isOpen: true,
					alertType: "warning",
					message: "Користувач з такою поштою вже існує, скористайтесь сторінкою авторизації!"
				});
			} else {
				setOpenSnackbar({
					isOpen: true,
					alertType: "error",
					message: "Під час реєстрації виникла помилка, перевірте коректність даних, або спробуйте пізніше!"
				});
			}
		})
	};

	return (
		<div className="overflow-hidden">
			<div className={styles.background} />
			<Box component="form" onSubmit={handleSubmit} className={styles.loginFormContainer}>
				<span className="block text-4xl font-bold text-gray mb-2 text-center">Зареэструватись в 4Help admin panel</span>
				<TextField
					margin="dense"
					id="email"
					label="Email"
					name="email"
					autoComplete="email"
					autoFocus
					required
					fullWidth
				/>
				<TextField
					margin="dense"
					name="password"
					label="Пароль"
					type="password"
					id="password"
					autoComplete="current-password"
					required
					fullWidth
				/>
				<TextField
					margin="dense"
					name="repeat-password"
					label="Повторіть пароль"
					type="password"
					id="repeat-password"
					required
					fullWidth
				/>
				<TextField
					margin="dense"
					fullWidth
					name="name"
					label="Ім'я"
					type="text"
					id="name"
				/>
				<TextField
					margin="dense"
					fullWidth
					name="lastName"
					label="Прізвище"
					type="text"
					id="lastName"
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Зареєструватись
				</Button>
				<Link href={'/r/sign-in'} variant="body2">У вас є акаунт? Увійти</Link>
			</Box>
			<Snackbar
				open={openSnackbar.isOpen}
				autoHideDuration={5000}
				onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
			>
				<MuiAlert
					onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
					severity={openSnackbar.alertType}
					sx={{ width: '100%' }}
				>
					{openSnackbar.message}
				</MuiAlert>
			</Snackbar>
		</div>
	)
};

export default SignUp;