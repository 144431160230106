import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Alert,
  Snackbar, Tooltip
} from '@mui/material';
import limbo from '../../../../services/core/limboclient';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import client from '../../../../services/core/limboclient';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const EditTherapistModal = ({ isOpen, onClose, selectedTherapist }) => {
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    isOpen: false,
    alertType: 'success',
    message: ''
  });
  const [newNote, setNewNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && selectedTherapist) {
      limbo.call(`=>therapist_details ~ ${JSON.stringify({ id: selectedTherapist.id })}`).then(result => {
        if (result.error === 'not_authorized') {
          navigate('/r/sign-in');
        }
        if (!!result) {
          setTherapist(result);
          setIsLoading(false);
        }
      });
    }
  }, [isOpen, selectedTherapist]);

  const handleClose = () => {
    onClose();
    setIsLoading(true);
    setTherapist(null);
  };

  const handleUpdateTherapistDetails = () => {
    limbo.call(`=>therapist_updateProfile ~ ${JSON.stringify({
      details: therapist.details,
      specialization: therapist.specialization,
      img: therapist.image,
      therapist: therapist.id,
      searchable: therapist.searchable,
      rate: therapist.rate,
      share: therapist.share,
      availableFrom: therapist.availableFrom
    })}`).then(result => {
      if (result.error === 'not_authorized') {
        navigate('/r/sign-in');
      }
      if (result.success) {
        setOpenSnackbar({
          isOpen: true,
          alertType: 'success',
          message: 'Дані терапевта були успішно оновлені!'
        });
        handleClose();
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: 'error',
          message: 'Дані терапевта НЕ було оновлено, спробуйте ще раз!'
        });
      }
    });
  };

  const handleAddNewNote = () => {
    if (newNote?.length > 0) {
      limbo.call(`=>therapist_addNote ~ ${JSON.stringify({
        therapist: therapist?.id,
        note: newNote
      })}`).then((result) => {
        limbo.call(`=>therapist_details ~ ${JSON.stringify({ id: selectedTherapist.id })}`).then(result => {
          if (!!result) setTherapist(result);
        });

        if (result.success) {
          setOpenSnackbar({
            isOpen: true,
            alertType: 'success',
            message: 'Нотатка була успішно додана!'
          });
          setNewNote(null);
        } else {
          setOpenSnackbar({
            isOpen: true,
            alertType: 'error',
            message: 'Нотатка не була додана, спробуйте ще раз!'
          });
          setNewNote(null);
        }
      });
    } else {
      setOpenSnackbar({
        isOpen: true,
        alertType: 'error',
        message: 'Нотатка не була додана, спробуйте ще раз!'
      });
      setNewNote(null);
    }
  };

  const handleDeleteNote = (noteId) => {
    client.call(`=>note_delete ~ ${JSON.stringify({
      id: noteId
    })}`).then((result) => {
      if (result.success) {
        limbo.call(`=>therapist_details ~ ${JSON.stringify({ id: selectedTherapist.id })}`).then(result => {
          if (!!result) setTherapist(result);
        });
        setOpenSnackbar({
          isOpen: true,
          alertType: 'success',
          message: 'Нотатка була успішно видалена!'
        });
      } else {
        setOpenSnackbar({
          isOpen: true,
          alertType: 'error',
          message: 'Нотатка не була видалена, спробуйте ще раз!'
        });
      }
    });
  }
  
  return (
    <>
      {therapist && (
        <Dialog open={isOpen && !isLoading} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle className="text-center !font-bold">
            Редагування даних терапевта {therapist?.firstName} {therapist?.lastName}
          </DialogTitle>
          <DialogContent>
            {therapist?.image && (
              <img
                src={therapist?.image}
                alt="Фото терапевта"
                className="mr-5 w-[100px] rounded-md"
              />
            )}
            <TextField
              fullWidth
              label="Посилання на зображення"
              value={therapist?.image}
              onChange={(event) => setTherapist({...therapist, image: event.target.value})}
              className="!mt-5"
            />
            <TextField
              value={therapist?.details}
              fullWidth
              label="Опис"
              id="details"
              onChange={(event) => setTherapist({...therapist, details: event.target.value})}
              className="!mt-5"
              required
            />
            <TextField
              value={therapist?.specialization}
              fullWidth
              label="Спеціалізація"
              id="specialization"
              onChange={(event) => setTherapist({...therapist, specialization: event.target.value})}
              className="!mt-5"
              required
            />
            <TextField
              fullWidth
              label="Тариф"
              id="rate"
              value={therapist?.rate}
              onChange={(event) => setTherapist({...therapist, rate: event.target.value})}
              className="!mt-5"
              required
            />
            <TextField
              fullWidth
              label="Доля терапевта"
              id="share"
              value={therapist?.share}
              onChange={(event) => setTherapist({...therapist, share: event.target.value})}
              className="!mt-5"
              required
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  label="Дата з якої терапевт може брати нових клієнтів"
                  value={therapist?.availableFrom}
                  onChange={(newValue) => setTherapist({...therapist, availableFrom: newValue})}
                  className="!mt-5 w-full"
                />
              </DemoContainer>
            </LocalizationProvider>
            <div className="mt-3">
              <p className="font-bold mb-2">Нотатки:</p>
              {therapist?.notes?.length > 0 && (
                therapist?.notes?.map((item) => {
                  return (
                    <div className="border border-[#a1a1a1] p-2 rounded-lg relative mb-3">
                      <Tooltip title="Видалити нотатку" placement="top-end">
                        <CloseIcon
                          color="error"
                          className="absolute top-2 right-2 cursor-pointer"
                          onClick={() => handleDeleteNote(item.id)}
                        />
                      </Tooltip>
                      <p className="w-[90%]">{item.note}</p>
                      <p className="text-sm text-gray flex items-center justify-end mt-2">
                        {moment(new Date(item.createDate)).locale('uk')?.format('LLL')}
                      </p>
                    </div>
                  )
                })
              )}
              {newNote !== null && (
                <TextField
                  value={newNote}
                  variant="outlined"
                  size="small"
                  onChange={(event) => setNewNote(event.target.value)}
                  onBlur={handleAddNewNote}
                  className="w-[100%] !rounded-lg"
                />
              )}
              <Button variant="contained" className="!mt-5" onClick={() => setNewNote('')}>
                Додати нотатку
              </Button>
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={therapist?.searchable}
                  onChange={(event) => setTherapist({
                    ...therapist,
                    searchable: event.target.checked
                  })}
                  className="ml-5"
                />
              }
              label="Терапевт доступний для вибору клієнтам"
              labelPlacement="start"
              className="!mt-5"
            />
            <div className="grid grid-cols-2 gap-10 mt-5">
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={handleClose}
              >
                Відмінити редагування
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleUpdateTherapistDetails}
              >
                Зберегти зміни
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar.isOpen}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
      >
        <Alert
          onClose={() => setOpenSnackbar({...openSnackbar, isOpen: false})}
          severity={openSnackbar.alertType}
          sx={{ width: '100%' }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
};

export default EditTherapistModal;