import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import ClientsView from './components/clients-view';
import InvitationsView from './components/invitations-view';

const TherapyClientsPage = () => {
	const [activeTab, setActiveTab] = useState('CLIENTS');

	return (
		<div>
			<ToggleButtonGroup
				color="primary"
				key={activeTab}
				value={activeTab}
				defaultValue={'CLIENTS'}
				defaultChecked={true}
				exclusive
				onChange={(event, viewName) => {
					if (viewName !== null) {
						setActiveTab(viewName)
					}
				}}
				aria-label="Platform"
				className="w-[100%]"
			>
				<ToggleButton value="CLIENTS" className="!normal-case">Клієнти</ToggleButton>
				<ToggleButton value="INVITATIONS" className="!normal-case">Запрошення</ToggleButton>
			</ToggleButtonGroup>
			<div className="mt-5">
				{activeTab === 'CLIENTS' && <ClientsView /> }
				{activeTab === 'INVITATIONS' && <InvitationsView /> }
			</div>
		</div>
	)
};

export default TherapyClientsPage;