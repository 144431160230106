import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Select,
	OutlinedInput,
	MenuItem,
	FormControl,
	Button
} from '@mui/material';
import { handleMoodCategoryView } from '../../index';
import client from '../../../../services/core/limboclient';

const MoodModal = ({ isOpen, onClose, selectedMood, categoriesList }) => {
	const [mood, setMood] = useState(selectedMood ?? null);

	useEffect(() => {
		if (isOpen && selectedMood) setMood(selectedMood);
	}, [isOpen, selectedMood]);

	const handleClose = () => {
		onClose();
		setTimeout(() => {
			setMood(null);
		}, 500);
	};

	const handleChangeIntensity = (intensity) => {
		if (intensity > 10) setMood({ ...mood, intensity: 10 });
		if (intensity < 0) setMood({ ...mood, intensity: 0 });
	};

	const handleSubmit = () => {
		if (selectedMood) {
			client.call(`=> mood_update ~ ${JSON.stringify({
				id: selectedMood.id,
				name: mood.name,
				category: mood.category,
				intensity: mood.intensity
			})}`).then((result) => {
				if (result.success) handleClose();
			});
		} else {
			client.call(`=> mood_create ~ ${JSON.stringify({
				name: mood.name,
				category: mood.category,
				intensity: mood.intensity
			})}`).then((result) => {
				if (result.success) handleClose();
			});
		}
	};

	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle className="text-center !font-bold">
				{selectedMood ? 'Редагування настрою' : 'Додавання настрою'}
			</DialogTitle>
			<DialogContent>
				<TextField
					label="Назва настрою"
					variant="outlined"
					placeholder="Назва настрою"
					value={mood?.name}
					onChange={(event) => setMood({...mood, name: event.target.value})}
					className="w-full !mt-1"
				/>
				<div className="flex items-center justify-between mt-5 w-full">
					<TextField
						label="Ступінь"
						variant="outlined"
						type="number"
						placeholder="Ступінь настрою"
						value={mood?.intensity?.toString()}
						onChange={(event) => setMood({...mood, intensity: Number(event.target.value)})}
						onBlur={(event) => handleChangeIntensity(Number(event.target.value))}
						className="block !w-full !mr-5"
						sx={{
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
					/>
					{categoriesList?.length > 0 && (
						<FormControl variant="standard" key="select-mood-category" className="w-full">
							<Select
								labelId="category-mood"
								value={mood ? mood.category : null}
								input={<OutlinedInput />}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								className="!w-full !bg-white"
								label="Категорія настрою"
								renderValue={(selected) => {
									if (!selected) return <em className="opacity-70">Оберіть категорію настрою</em>;

									return <p className="grid grid-cols-2 gap-y-2 gap-x-2">
										{categoriesList.find((item) => item.id === mood?.category)?.name?.split(' ').map((item) =>
											handleMoodCategoryView(item))}
									</p>
								}}
								onChange={(event) =>
									setMood({ ...mood, category: event.target.value })
								}
							>
								{categoriesList.map((category, index) => (
									<MenuItem
										value={category.id}
										key={index.toString()}
										className="grid grid-cols-2 gap-y-2 gap-x-2"
									>
										{category?.name?.split(' ').map((item) => handleMoodCategoryView(item))}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</div>
				<div className="flex items-center justify-between mt-5">
					<Button
						variant="contained"
						color="error"
						onClick={handleClose}
						className="!mr-5 !w-full"
					>
						Відмінити
					</Button>
					<Button
						variant="contained"
						color="info"
						onClick={handleSubmit}
						className="!w-full"
					>
						{selectedMood ? 'Редагувати настрій' : 'Створити настрій'}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
};

export default MoodModal;