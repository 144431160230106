import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Snackbar, Alert, Link } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import styles from './index.module.scss';
import authService from '../../../services/authService';

const SignIn = ({ navigateAfterLogin, isCanSignUp = true }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
	const [authError, setAuthError] = useState('');

	useEffect(() => {
		if (authService.isDevMode()) {
			authService.updateSessionId();
		}
	}, []);

	useEffect(() => {
		if (id) localStorage.setItem('inviteId', id);
	}, [id]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = data.get('email');
		const password = data.get('password');

		const loginData = await authService.login(email, password, !!navigateAfterLogin ?
			() => navigateAfterLogin() : () => navigate('/r/test/index')
		);

		if (loginData.error === 'invalid_login_or_password') {
			setIsOpenSnackbar(true);
			setAuthError('Неправильний логін або пароль, перевірте коректність даних та спробуйте ще раз!');
		}

		if (loginData.error === 'user_not_confirm') {
			const resendConfirmCode = await authService.sendConfirmCode(email);
			if (resendConfirmCode.success === true) navigate('/r/confirm');
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") return;
		setIsOpenSnackbar(false);
	};

	const handleGoogleAuth = (credentialResponse) => {
		authService.googleLogin(credentialResponse.credential,
			!!navigateAfterLogin ? () => navigateAfterLogin() : () => navigate('/r/test/index')
		)
	};

	return (
		<div>
			<div className={styles.background} />
			<Box component="form" onSubmit={handleSubmit} noValidate className={styles.loginFormContainer}>
				<span className="block text-4xl font-bold text-gray mb-2 text-center">Увійти 4Help admin panel</span>
				<TextField
					error={!!authError}
					margin="normal"
					id="email"
					label="Email"
					name="email"
					autoComplete="email"
					onFocus={() => setAuthError('')}
					helperText={!!authError && "Невірний пароль або email"}
					required
					fullWidth
					autoFocus
				/>
				<TextField
					error={!!authError}
					margin="normal"
					name="password"
					label="Пароль"
					type="password"
					id="password"
					autoComplete="current-password"
					onFocus={() => setAuthError('')}
					helperText={!!authError && "Невірний пароль або email"}
					required
					fullWidth
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Увійти
				</Button>
				<GoogleOAuthProvider clientId="20815819625-supgh0qi7i2tmet7v824u2844cel3cvi.apps.googleusercontent.com">
					<GoogleLogin
						onSuccess={(credentialResponse) => handleGoogleAuth(credentialResponse)}
						onError={() => {
							console.log('Login Failed');
						}}
						size="large"
						width={500}
					/>
				</GoogleOAuthProvider>
				{isCanSignUp && (
					<Link href={'/r/sign-up'} variant="body2" className="!mt-3">Немає акаунта? Зареєструйся</Link>
				)}
			</Box>
			<Snackbar
				sx={{ height: "5%" }}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={isOpenSnackbar}
				autoHideDuration={5000}
				onClose={handleCloseSnackbar}
			>
				<Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
					{authError}
				</Alert>
			</Snackbar>
		</div>
	)
};

export default SignIn;