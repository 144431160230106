import React, { useEffect, useState } from 'react';
import {
	TableContainer,
	Table, TableHead,
	TableRow,
	TableCell,
	TableBody, Button
} from '@mui/material';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import styles from './index.module.scss';
import UpdateTestModal from './components/update-test-modal';
import DeleteTestModal from './components/delete-test-modal';
import { useNavigate } from 'react-router-dom';
import testService from '../../services/testService';
import CreateTestModal from './components/create-test-modal';

const columns = [
	{ id: 'name', label: 'Назва тесту' },
	{ id: 'details', label: 'Опис тесту' },
]

const TherapyTestsPage = () => {
	const navigate = useNavigate();
	const [tests, setTests] = useState([]);
	const [selectedTest, setSelectedTest] = useState(null);
	const [deletedTest, setDeletedTest] = useState('');
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
	const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);

	useEffect(() => {
		(async () => {
			setTests(await testService.getAllTests(() => navigate('/r/sign-in')));
		})();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (!isOpenUpdateModal) {
				setSelectedTest(null);
			}
			if (!isOpenDeleteModal) setDeletedTest(null);
		}, 500);

		if (!isOpenUpdateModal && !isOpenDeleteModal) {
			(async () => {
				setTests(await testService.getAllTests(() => navigate('/r/sign-in')));
			})();
		}
	}, [isOpenUpdateModal, isOpenDeleteModal, isOpenCreateModal]);

	const handleDeleteTest = (testName) => {
		setDeletedTest(testName);
		setIsOpenDeleteModal(true);
	};

	return (
		<div>
			{tests?.length > 0 &&
				<div>
					<TableContainer sx={{maxHeight: "60vh", width: '100%', position: 'relative', marginBottom: '20px'}}>
						<Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
							<TableHead>
								<TableRow>
									{tests.length > 0 && columns.map((item) => (
										<TableCell
											key={item.id}
											align="left"
											style={{minWidth: 50, fontWeight: 'bold'}}
										>
											{item.label}
										</TableCell>
									))}
									<TableCell
										key="edit-cell"
										align="left"
										style={{minWidth: 50, fontWeight: 'bold'}}
									/>
									<TableCell
										key="delete-cell"
										align="left"
										style={{minWidth: 50, fontWeight: 'bold'}}
									/>
								</TableRow>
							</TableHead>
							<TableBody>
								{tests.map((row, index) => {
									return (
										<TableRow
											hover
											tabIndex={-1}
											key={row.id}
										>
											{columns.map((column) => {
												return (
													<TableCell key={column.id} align="left" className={styles.rowItemContainer}>
														{row[column.id]}
													</TableCell>
												);
											})}
											<TableCell key="edit" align="left" className={styles.rowItemContainer}>
												<Button
													variant="contained"
													className="!bg-primary-light !text-white !normal-case !pr-3 !bg-opacity-60"
													onClick={() => {
														setSelectedTest(row);
														setIsOpenUpdateModal(true);
													}}
												>
													Редагувати
													<BorderColorTwoToneIcon className="ml-2" color="warning"/>
												</Button>
											</TableCell>
											<TableCell key="delete" align="left" className={styles.rowItemContainer}>
												<Button
													variant="contained"
													className="!bg-gray !text-white !normal-case !pr-3 !bg-opacity-50"
													onClick={() => handleDeleteTest(row)}
												>
													Видалити
													<DeleteForeverTwoToneIcon color="error" className="ml-2"/>
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => setIsOpenCreateModal(true)}
						className="!mt-5"
					>
						Створити новий тест
						<PostAddTwoToneIcon color="primary" className="ml-2" />
					</Button>
				</div>
			}
			{tests?.length === 0 && <span>Немає доступних тестів...</span>}
			<UpdateTestModal
				isOpen={isOpenUpdateModal}
				close={() => {
					setSelectedTest(null)
					setIsOpenUpdateModal(false)
				}}
				selectedTest={selectedTest}
			/>
			<CreateTestModal
				isOpen={isOpenCreateModal}
				close={() => setIsOpenCreateModal(false)}
			/>
			<DeleteTestModal
				isOpen={isOpenDeleteModal}
				close={() => {
					setIsOpenDeleteModal(false)
				}}
				test={deletedTest}
				deleteTest={() => {
					testService.deleteTest(deletedTest.id);
					setIsOpenDeleteModal(false);
				}}
			/>
		</div>
	)
};

export default TherapyTestsPage;